import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import ReactHtmlParser from 'react-html-parser';
import ConfirmationDialog from 'shared/PageElements/ConfirmationDialog';

import classes from './Plans.module.scss';
import { subscriptionPlans } from 'consts/consts';

const { supporterMonthly, supporterYearly, readerMonthly, readerYearly, avidReaderMonthly, avidReaderYearly, freeTrial} = subscriptionPlans;

function Plans({ data }) {
    const { 
        subscriptionTitle, 
        subscriptionSubtitle, 
        freePlanDescription,
        supporterPlanDescriptionTitle,
        supporterPlanDescription,
        readerPlanDescriptionTitle,
        readerPlanDescription,
        avidReaderPlanDescriptionTitle,
        avidReaderPlanDescription,
     } = data
    const [isMonthlyView, setIsMonthlyView] = useState(true);

    const history = useHistory();
    const user = useSelector(state => state.userReducer.user);
    const hasCurrentSubscription = user?.currentSubscription;
    const noSubscription = !hasCurrentSubscription;


    const isFree = user?.currentSubscription?.status === 'trialing';
    const isSupporter = user?.currentSubscription?.status !== 'trialing' && (user?.currentSubscription?.plan === supporterMonthly.plan || user?.currentSubscription?.plan === supporterYearly.plan);
    const isReader = user?.currentSubscription?.status !== 'trialing' && (user?.currentSubscription?.plan === readerMonthly.plan || user?.currentSubscription?.plan === readerYearly.plan);
    const isAvidReader = user?.currentSubscription?.status !== 'trialing' && (user?.currentSubscription?.plan === avidReaderMonthly.plan || user?.currentSubscription?.plan === avidReaderYearly.plan);

    const supporterMonthlyPrice = supporterMonthly.price;
    const supporterYearlyPrice = supporterYearly.price;
    const readerMonthlyPrice = readerMonthly.price;
    const readerYearlyPrice = readerYearly.price;
    const avidReaderMonthlyPrice = avidReaderMonthly.price;
    const avidReaderYearlyPrice = avidReaderYearly.price;
    const freeTrialPrice = freeTrial.price;

    const handleType = (monthly) => {
        if (monthly) {
            setIsMonthlyView(true);
        } else {
            setIsMonthlyView(false);
        }
    }
    const type = isMonthlyView ? 'month' : 'year';

    const navigateTo = (subscription) => {
        noSubscription ? 
            history.push({ 
                pathname: "/payment", 
                state: { subscription: subscription }}) 
            : history.push('/subscription-details')
    }

    const handleButtonClick = (subscription) => {
        if (hasCurrentSubscription) {
            confirmAlert({
                customUI: ({ onClose }) => (
                    <ConfirmationDialog 
                        handleClose={() => { onClose()}}   
                        changeSubscription
                        onClickFirstButton={() => { 
                            history.push('/subscription-details')
                            onClose()
                        }}
                    />  
                )
            })
        } else {
            navigateTo(subscription) 
        }
    }

    return (
    <>
        <section className={`${classes.Choose} new h-flex`}>
            <h2 className='font-italic font-36 ls-2 mb-5'>{subscriptionTitle}</h2>
            <p className='ls-1 font-22 display-block font-secondary'>{subscriptionSubtitle}</p>

            <div className={`${isMonthlyView ? '' : classes.Choose__Annually}`}>
                <button id='gtm-subscription-choose-monthly' onClick={() => handleType(true)}>Monthly</button>
                <button id='gtm-subscription-choose-annually' onClick={() => handleType(false)}>Annually</button>

                <span className='font-16 font-400 font-secondary'>2 months free</span>
            </div>
        </section>
        <section className={`${classes.Plans}`}>
            <section  className={`${classes.Plans__Box}`}>
                <div>
                    <h3 className='font-italic font-42 ls-2 mb-5'>Free</h3>
                    <span className='font-secondary font-11 ls-5 font-uppercase display-block font-brown'>No credit card required.</span>

                    <span className='font-secondary display-block font-36 font-300 pt-50'>£{freeTrialPrice} <span className='font-18 font-brick-red'>forever</span></span>
                </div>

                {noSubscription && !user && <Link id='gtm-subscription-page-join-for-free' to='/sign-up' className='transparent-button'>Join Spiracle for free</Link>}
                {isFree && <span className='font-secondary font-14 ls-18 font-uppercase font-500 font-blue display-block mt-10 mb-45'>Your plan</span>}

                <div className='font-secondary'>
                    <span className={`${classes.Plans__PlanTitle} font-14 ls-20 font-uppercase`}></span>
                    {ReactHtmlParser(freePlanDescription)}
                </div>
            </section>

            <section  className={`${classes.Plans__PremiumBox}`}>
                <h2 className='font-18 ls-25 font-secondary font-uppercase text-center'>Premium plans</h2>

                <div>
                    <section className={`${classes.Plans__Box}`}>
                        <div>
                            <h3 className='font-italic font-42 ls-2 mb-5'>Supporter</h3>
                            <span className='font-20 display-block font-secondary'>Supporting independent publishing</span>

                            <span className='font-secondary display-block font-secondary font-36 font-300 pt-45 pb-20'>£{isMonthlyView ? supporterMonthlyPrice : supporterYearlyPrice} <span className='font-18 font-300 font-brick-red'>/ {type}</span></span>
                            
                            {(noSubscription || isFree) && 
                                <button 
                                    className='new-button-red' 
                                    onClick={() => handleButtonClick(isMonthlyView ? supporterMonthly : supporterYearly)}
                                    id={`${noSubscription ? (isMonthlyView ? 'get-supporter-plan-monthly' : 'get-supporter-plan-annually') : (isMonthlyView ? 'upgrade-to-supporter-plan-monthly' : 'upgrade-to-supporter-plan-annually')}`}
                                >{noSubscription ? 'Get Supporter plan' : 'Upgrade to Supporter plan'}</button>}
                            {(isReader || isAvidReader) && <button id='gtm-downgrade-to-supporter-plan' className='link-button font-secondary' onClick={() => handleButtonClick()}>Downgrade to Supporter plan</button>}
                            {isSupporter && 
                                <div className='font-secondary h-flex h-flex-between h-flex-middle'>
                                    <span className='font-secondary font-14 ls-18 font-uppercase font-500 font-blue'>Your plan</span>
                                    <Link id='gtm-subscription-page-supporter-manage' to='/subscription-details' className='transparent-button'>Manage</Link>
                                </div>
                            }
                        </div>

                        <div className='font-secondary'>
                            <span className={`${classes.Plans__PlanTitle} font-14 ls-20 font-uppercase`}>{supporterPlanDescriptionTitle}</span>
                            {ReactHtmlParser(supporterPlanDescription)}
                        </div>
                    </section>

                    <section  className={`${classes.Plans__Box}`}>
                        <div>
                            <h3 className='font-italic font-42 ls-2 mb-5'>Reader</h3>
                            <span className='font-20 display-block font-secondary'><strong>1 </strong>free audiobook every month</span>

                            <span className='font-secondary font-36 font-300 display-block pt-45 pb-20'>£{isMonthlyView ? readerMonthlyPrice : readerYearlyPrice} <span className='font-18 font-300 font-brick-red'>/ {type}</span></span>

                            {(noSubscription || isFree || isSupporter) && 
                                <button 
                                    className='new-button-red' 
                                    onClick={() => handleButtonClick(isMonthlyView ? readerMonthly : readerYearly)}
                                    id={`${noSubscription ? (isMonthlyView ? 'get-reader-plan-monthly' : 'get-reader-plan-annually') : (isMonthlyView ? 'upgrade-to-reader-plan-monthly' : 'upgrade-to-reader-plan-annually')}`}
                                >{noSubscription ? 'Get Reader plan' : 'Upgrade to Reader plan'}</button>}
                            {isReader && 
                                <div className='font-secondary h-flex h-flex-between h-flex-middle'>
                                    <span className='font-secondary font-14 ls-18 font-uppercase font-500 font-blue'>Your plan</span>
                                    <Link id='gtm-subscription-page-reader-manage' to='/subscription-details' className='transparent-button'>Manage</Link>
                                </div>
                            }
                            {isAvidReader && <button id='gtm-downgrade-to-reader-plan' className='link-button font-secondary mt-5' onClick={() => handleButtonClick()}>Downgrade to Reader plan</button>}
                        </div>


                        <div className='font-secondary'>
                            <span className={`${classes.Plans__PlanTitle} font-14 ls-20 font-uppercase`}>{readerPlanDescriptionTitle}</span>
                            {ReactHtmlParser(readerPlanDescription)}
                        </div>
                    </section>

                    <section className={`${classes.Plans__Box}`}>
                        <div>
                            <h3 className='font-italic font-42 ls-2 mb-5'>Avid Reader</h3>
                            <span className='font-20 display-block font-secondary'><strong>2 </strong>free audiobook every month</span>

                            <span className={`${classes.Plans__Box__BestValue} best-value font-secondary font-12 ls-20 mt-13 font-uppercase display-table`}>Best value</span>

                            <span className='font-secondary font-36 font-300 display-block pt-9 pb-20'>£{isMonthlyView ? avidReaderMonthlyPrice : avidReaderYearlyPrice} <span className='font-18 font-300 font-brick-red'>/ {type}</span></span>

                            {(noSubscription || isFree || isSupporter || isReader) && 
                                <button 
                                    className='new-button-red' 
                                    onClick={() => handleButtonClick(isMonthlyView ? avidReaderMonthly : avidReaderYearly)}
                                    id={`${noSubscription ? (isMonthlyView ? 'get-avid-reader-plan-monthly' : 'get-avid-reader-plan-annually') : (isMonthlyView ? 'upgrade-to-avid-reader-plan-monthly' : 'upgrade-to-avid-reader-plan-annually')}`}
                                >{noSubscription ? 'Get Avid Reader plan' : 'Upgrade to Avid Reader plan'}</button>}
                            {isAvidReader && 
                                <div className='font-secondary h-flex h-flex-between h-flex-middle'>
                                    <span className='font-secondary font-14 ls-18 font-uppercase font-500 font-blue'>Your plan</span>
                                    <Link id='gtm-subscription-page-avid-reader-manage' to='/subscription-details' className='transparent-button'>Manage</Link>
                                </div>
                            }
                        </div>

                        <div className='font-secondary'>
                            <span className={`${classes.Plans__PlanTitle} font-14 ls-20 font-uppercase`}>{avidReaderPlanDescriptionTitle}</span>
                            {ReactHtmlParser(avidReaderPlanDescription)}
                        </div>
                    </section>
                </div>
            </section>
        </section>
    </>
    )
}
export default Plans