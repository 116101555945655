import * as discountActionTypes from '../../../store/actions/discountActions';

import React, { Component } from 'react';
import { connect } from 'react-redux';

// import { debounce } from '../../../shared/Functions/Debounce';

import ApiService from '../../../services/api.service';
import Loader from '../../../shared/Loader/Loader'

import { subscriptionPlans } from '../../../consts/consts'

import { PromotionDiscountText, StyledDiscount } from './style';

import { ReactComponent as SpiracleLogoSmall } from '../../../images/Spiral.svg';

class Discount extends Component {

    constructor(props) {
        super(props);
        this.state = {
            discountCode: this.props.membershipPromoCode ? this.props.membershipPromoCode.toUpperCase() : '',
            discountMessage: '',
            discountError: false,
            isLoadingDiscount: this.props.membershipPromoCode ? true : false,
            plan: this.props.plan,
            showDiscountField: false
        }
        // this.debounceDiscount = debounce(this.checkDiscount, 400);
    }

    componentDidMount() {
        this.props.onRemoveDiscount()   
        
        if (this.props.membershipPromoCode) {
            this.setDiscount()
        }
    }

    checkDiscount = () => {
        this.setState({
            isLoadingDiscount: true
        }, () => {
            this.props.pullDiscount({isLoadingDiscount: this.state.isLoadingDiscount})
        })

        ApiService.getDiscount(this.state.discountCode).then(
            r => {
                if (r.data.active) {
                    if (r.data.discount_type === 'all') {
                        this.setState({
                            discountError: false,
                            discountMessage: '',
                        })

                        this.props.onAddDiscount({ relativeDiscount: r.data.relative_discount, discountCode: r.data.discount_code })
                    } else {
                        if (this.props.membership) {
                            const subscription = Object.values(subscriptionPlans).find((item) => item.plan === this.state.plan);
                            const isMonthly = subscription?.monthly;
                            const isYearly = subscription?.yearly;
                            
                            if ((r.data.discount_type === 'subscription') || (r.data.discount_type === 'monthly' && isMonthly) || (r.data.discount_type === 'yearly' && isYearly)) {
                                this.setState({
                                    discountError: false,
                                    discountMessage: '',
                                })

                                this.props.onAddDiscount({ relativeDiscount: r.data.relative_discount, discountCode: r.data.discount_code })
                            } else {
                                this.setState({
                                    discountError: true,
                                    discountMessage: 'This discount code is not valid'
                                })

                                this.props.onRemoveDiscount()
                            }
                        } else {
                            if (r.data.discount_type === 'book') {
                                this.setState({
                                    discountError: false,
                                    discountMessage: '',
                                })

                                this.props.onAddDiscount({ relativeDiscount: r.data.relative_discount, discountCode: r.data.discount_code })
                            } else {
                                this.setState({
                                    discountError: true,
                                    discountMessage: 'This discount code is not valid'
                                })

                                this.props.onRemoveDiscount()
                            }    
                        }
                    }
                } else {
                    this.setState({
                        discountError: true,
                        discountMessage: 'This discount code is not valid'
                    })

                    this.props.onRemoveDiscount()
                }

                this.setState({
                    isLoadingDiscount: false
                }, () => {
                    this.props.pullDiscount({isLoadingDiscount: this.state.isLoadingDiscount})
                })
            }
        ).catch((err) => {
            this.setState({
                discountError: true,
                discountMessage: 'This discount code is not valid',
                isLoadingDiscount: false
            }, () => {
                this.props.pullDiscount({isLoadingDiscount: this.state.isLoadingDiscount})
            })

            this.props.onRemoveDiscount()
        })
    }

    setDiscount = e => {
        this.setState({
            discountCode: this.props.membershipPromoCode ? this.props.membershipPromoCode.toUpperCase().trim() : e.target.value.toUpperCase().trim(),
        }, () => {
            if (e) {
                if (e.target.value.trim().length > 0) {
                    //this.debounceDiscount();

                } else {
                    this.setState({
                        discountError: false,
                        discountMessage: '',
                    })
                }
            } else {
                if (this.props.membershipPromoCode) {
                    this.checkDiscount();

                } else {
                    this.setState({
                        discountError: false,
                        discountMessage: '',
                    })
                }
            }
        })
    }

    handleDiscountBlur = e => {
        if (e.target.value.trim().length === 0) {
            this.setState({
                discountError: false,
                discountMessage: '',
            })
        }
    }

    handleDiscountFocus = e => {
        if (e.target.value.trim().length === 0) {
            this.setState({
                discountError: false,
                discountMessage: '',
            })
        }
    }

    handleApply = (e) => {
        e.preventDefault()
        this.checkDiscount()
    }

    handleShowDiscountField = (e) => {
        e.preventDefault()
        this.setState({
            showDiscountField: !this.state.showDiscountField
        })
    }

    render() {
        return (
            <div className="mb-40">
                <StyledDiscount style={{display: this.props.membershipPromoCode ? 'none' : 'block'}}>
                    {/* <label>Discount code</label> */}
                    <button id='gtm-have-discount-code' onClick={(e) => this.handleShowDiscountField(e)} className={`${this.state.showDiscountField ? 'show' : ''}`}>
                        <span>Have a discount code?</span> <i className='icon-arrow-down font-8'></i>
                    </button>

                    <div style={{display: this.state.showDiscountField ? 'flex' : 'none'}}>
                        <div>
                            <input  
                                className={`bg-white ${this.state.discountCode.length && this.state.discountError && 'invalid'}`}
                                type="text"
                                name="discountCode"
                                id="discountCode" 
                                placeholder="Discount code"
                                onChange={(e) => this.setDiscount(e)}
                                onBlur={(e) => this.handleDiscountBlur(e)}
                                onFocus={(e) => this.handleDiscountFocus(e)}
                                value={this.state.discountCode}
                                autoComplete="off"
                            />       
                            <div className="c-error-message mt-5">
                                {this.state.discountCode.length && this.state.discountError ? <div>{this.state.discountMessage}</div> : null}
                            </div> 
                        </div>

                        <button 
                            id='gtm-apply-discount-code' 
                            disabled={!this.state.discountCode.length || this.state.isLoadingDiscount} 
                            onClick={(e) => this.handleApply(e)} 
                            className={`sa-button-ghost sa-button--svg sa-button-rounded ${this.state.isLoadingDiscount ? 'submitting' : ''}`}
                        >
                            Apply code
                            <SpiracleLogoSmall />
                        </button>
                    </div>
                </StyledDiscount>

                {
                    this.props.membershipPromoCode ? (
                            this.state.isLoadingDiscount ? 
                                <Loader small /> : 
                                <PromotionDiscountText>
                                    {this.state.discountError ? 
                                        <div>No discount applied! <br/> Full price will be charged!</div> 
                                        : 
                                        <div>
                                            Rest assured no payment will be taken for the first month.
                                        </div>}
                                </PromotionDiscountText>
                    ) : null
                }                                
            </div>
        )
    }
}

const mapStateToProps = state => ({
    discount: state.discountReducer,
})

const mapDispatchToProps = dispatch => {
  return {
      onAddDiscount: (data) => dispatch(discountActionTypes.onAddDiscount(data)),
      onRemoveDiscount: () => dispatch(discountActionTypes.onRemoveDiscount()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Discount);
