import * as userActionTypes from '../../../store/actions/userActions';
import * as cartActionTypes from '../../../store/actions/cartActions';

import React, { Component } from 'react';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';

import { CurrentCard, Description, Details, Title, User } from './style';

import ApiService from '../../../services/api.service';
import DateFormater from '../../../services/format-date.service';
import ConfirmationDialog from 'shared/PageElements/ConfirmationDialog';
import SkeletonSubscriptionItem from '../../../components/UI/Skeleton/SkeletonSubscription/SkeletonSubscriptionItem';
import { subscriptionPlans } from 'consts/consts';

class SubscriptionItem extends Component {
    state = {
        isSubscriptionExpanded: this.props.subscriptionsTotal === 1 ? true : false,
        id: this.props.item.id,
        isError: false,
        isLoading: false,
    }

    componentDidMount() {
        if (this.props.user.email === this.props.item.giftUser?.email) {
            ApiService.getUser().then(
                user => {
                    this.props.onSetUser(user.data);
                }
            )
        }
    }

    // get price without subscription discount
    handleGetCartItems = (redirect) => {
        const cartId = this.props.user && this.props.user.cart && this.props.user.cart.uuid

        if (cartId) {
            ApiService.getCartItems(cartId).then(
                cart => {
                    this.props.onGetCartItems(cart.data['hydra:member'])

                    if (redirect) {
                        this.props.history.push('/subscription')
                    }
                }
            )
        } else {
            if (redirect) {
                this.props.history.push('/subscription')
            }
        }
    }  
    handleCancelSubscription = () => {
        this.setState({
            isLoading: true
        })
        ApiService.cancelSubscription(this.state.id).then(
            r => {
                if (this.state.id === (this.props.user.currentSubscription && this.props.user.currentSubscription.id)) {
                    // this.props.onRemoveSubscription()                   
                     this.handleGetCartItems(!this.props.user.paysSomeone)
                } else {
                    this.props.onRemovePaysSomeoneSubscription();
                }

                if (this.props.user.currentSubscription || this.props.user.paysSomeone) {
                    this.props.getAllSubscriptions()
                }

                this.setState({
                    isLoading: false
                })
            }
        ).catch(e => {
            this.setState({
                isError: true,
                isLoading: false
            })
        })
    }

    handleOpenCancelSubscription = () => {
        confirmAlert({
            customUI: ({ onClose }) => (
                <ConfirmationDialog
                    cancelSubscription
                    handleClose={() => { onClose() }}
                    onClickFirstButton={() => { this.handleCancelSubscription(); onClose() }}
                />
            ),
            //closeOnClickOutside: true,
        })
    }

    handleExpandCollapse = () => {
        this.setState({
            isSubscriptionExpanded: !this.state.isSubscriptionExpanded
        })
    }

    render() {
        const item = this.props.item;
        const subscriptionUser = item.gift ? item.giftUser.fullName ? item.giftUser.fullName : item.giftUser.email : this.props.user.fullName;
        // const isMonthly = item.plan === 1 || item.plan === 4 || item.plan === 6 || item.plan === 8; // Avid Reader || Reader || Supporter || Free Trial
        // const isYearly = item.plan === 2 || item.plan === 2 || item.plan === 7; // Avid Reader || Reader || Supporter
        const subscription = Object.values(subscriptionPlans).find((sub) => sub.plan === item.plan);
        const isMonthly = subscription?.monthly;
        const isYearly = subscription?.yearly;
        const type = subscription?.type;
        const type2 = subscription?.type2?.toUpperCase();
        const isOnce = item.discountDuration === 'once';
        const isRepeating = item.discountDuration === 'repeating';
        const repeatingDuration = item.discountDurationMonths;
        const isForever = item.discountDuration === 'forever';
        const name = subscription?.name;
        const originalPrice = subscription?.price;

        let discountEndDate;
        let isExpired;

        if (item.orderDate.date) {
            let startDate;

            if (item.gift) {
                if (item.claimedAt) {
                    startDate = moment.unix(item.claimedAt)
                } else {
                    startDate = null
                }
            } else {
                startDate = moment(item.orderDate.date)
            }


            if (isMonthly) {
                if (isOnce) {
                    discountEndDate = startDate ? startDate.add(1, 'M').format('M/D/Y') : null
                }
                if (isRepeating) {
                    discountEndDate = startDate ? startDate.add(repeatingDuration, 'M').format('M/D/Y') : null
                }
            }

            if (isYearly) {
                if (isOnce) {
                    discountEndDate = startDate ? startDate.add(1, 'y').format('M/D/Y') : null
                }
                if (isRepeating) {
                    discountEndDate = startDate ? startDate.add(repeatingDuration, 'y').format('M/D/Y') : null
                }
            }

            if (isForever) {
                discountEndDate = null;
            }

            if (discountEndDate) {
                const now = moment().format('M/D/Y')
                isExpired = new Date(now) >= new Date(discountEndDate)
            }
        }

        let content = <>
                        <Title onClick={this.handleExpandCollapse} className={`mt-30 font-25 ${this.state.isSubscriptionExpanded ? 'show' : ''}`}>
                            <Description>{type} for </Description>
                                <User title={subscriptionUser}>{subscriptionUser}</User> 
                            <i className='icon-arrow'></i>
                        </Title>
                        <Details className={`${this.state.isSubscriptionExpanded ? 'show' : ''}`}>
                            <h3 className='mt-45 mb-30 text-center font-primary font-36 font-italic letter-spacing-02'>{name}</h3>

                            <p className={`${item.percentDiscount && !isExpired ? 'old-price' : ''} text-center font-12 font-uppercase letter-spacing-15`}>
                                £{originalPrice}/{type2}
                            </p>

                            {item.percentDiscount && !isExpired ? (
                                <p className='text-center font-12 font-uppercase letter-spacing-15'>
                                    £{item.payment.amount !== '' && item.payment.amount >= 0 ? item.payment.amount.toFixed(2) : item.percentDiscount === 100 ? '0.00' : ''}/{type2}
                                </p>
                            ) : null}

                            <div className='mt-45 mb-60 text-center font-primary font-20'>
                                <div className='mb-32'>
                                    {type} subscription since <br />
                                    <span className='font-italic'>
                                        {item.gift ? (
                                            item.claimedAt ? DateFormater.formatUnixLong(item.claimedAt) : 'Not claimed'
                                        ) :
                                            DateFormater.formatUnixLong(item.spiraclePeriodStart)
                                        }
                                        
                                    </span>
                                </div>
                                {!item.gift ?
                                    item.canceledAt ? <div>
                                        Your {type} Premium Membership will expire on <br />
                                        <span className='font-italic'>{DateFormater.formatUnixLong(item.spiraclePeriodEnd)}</span> <br />
                                        Your payment has been cancelled.
                                    </div> : 
                                    <div>
                                        Next payment on <br />
                                        <span className='font-italic'>{DateFormater.formatUnixLong(item.spiraclePeriodEnd)}</span>
                                    </div>
                                    : <div>
                                        Subscription active until <br />
                                        <span className='font-italic'>
                                            {item.claimedAt ? DateFormater.formatUnixLong(item.validUntil) : 'Not claimed'}
                                        </span>
                                    </div>
                                }
                            </div>

                            {!item.gift ?
                            (
                                <>
                                    {item.payment ?
                                        <CurrentCard className='mb-24 font-12 font-uppercase text-center'>
                                            {item.payment.type === 'card' ? 
                                                <>
                                                    Current card details:
                                                    <div className='mt-12'>xxxx xxxx xxxx {item.cardLast4 || ''}</div>
                                                </> 
                                                : null
                                            }
                                            {item.payment.type === 'paypal' ? 
                                                <>
                                                    Current payment details:
                                                    <div className='mt-12'>paid with paypal</div>
                                                </>    
                                                : null
                                            }
                                        </CurrentCard> : null
                                    }

                            {item.canceledAt ? null : 
                                <>
                                    {item.payment.type !== 'paypal' ?
                                        <button
                                            id='gtm-update-payment-details'
                                            onClick={() => this.props.history.push({
                                                pathname: '/payment',
                                                state: { update: 'card', id: this.state.id }
                                            })}
                                            className="c-btn c-btn__ghost c-btn__ghost--square font-12 font-uppercase"
                                        >
                                            Update payment details
                                        </button>
                                        : null
                                    }
                                    <button
                                        onClick={this.handleOpenCancelSubscription}
                                        id='gtm-cancel-subscription'
                                        className="cancel-btn font-12 font-uppercase mt-18"
                                    >
                                        Cancel subscription
                                    </button>
                                </>}
                                </>
                            ) : null}
                        </Details>
                    </>
        if (this.state.isLoading) {
            content = <SkeletonSubscriptionItem />
        }

        return content
    }
}

const mapStateToProps = state => ({
    user: state.userReducer.user,
    membershipPrice: state.membershipPriceReducer
})

const mapDispatchToProps = dispatch => {
    return {
        onSetUser: (user) => dispatch(userActionTypes.onSetUser(user)),
        onRemoveSubscription: () => dispatch(userActionTypes.onRemoveSubscription()),
        onRemovePaysSomeoneSubscription: () => dispatch(userActionTypes.onRemovePaysSomeoneSubscription()),
        onGetCartItems: (cartItems) => dispatch(cartActionTypes.onGetCartItems(cartItems)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SubscriptionItem));