import {
    CardCvcElement,
    CardExpiryElement,
    CardNumberElement,
    useElements,
    useStripe,
} from "@stripe/react-stripe-js";
import { Button, CardDetails, CardElements, CardInput, Input, PaymentMethod, StyledCheckoutForm, Divider, Beige, Newsletter } from './style';
import { Terms } from '../../../../pages/Order/style';
import React, { useEffect, useState } from "react";

import ApiService from '../../../../services/api.service';
import Cookies from 'js-cookie'
import { Link } from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';
import { ReactComponent as SpiracleLogoSmall } from '../../../../images/Spiral.svg';
import cardImage from '../../../../images/cards.webp';
import cookie from "react-cookies";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux"
import SendMembershipAsGift from '../../MembershipGift/SendMembershipAsGift';
import Date from 'components/UI/Date/Date';

import payPal from '../../../../images/PayPal.png'

import config from 'config';

export default function CheckoutForm(props) {
    const stripe = useStripe();
    const elements = useElements();
    const history = useHistory();
    const location = useLocation();
    const user = useSelector(state => state.userReducer.user);
    const [registerError, setRegisterError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selected, setSelected] = useState('');
    const [state, setState] = useState({
        cardHolder: "",
        eMail: "",
        firstName: "",
        lastName: "",
        password: "",
    });
    const [validationState, setValidationState] = useState({
        cardHolder: true,
        eMail: true,
        firstName: true,
        lastName: true,
        password: true,
    })

    const [cardElementsComplete, setCardElementsComplete] = useState({
        number: false,
        expiry: false,
        cvc: false,
    })

    const [checkedPaymentMethod, setCheckedPaymentMethod] = useState(1)

    // const [isTermsChecked, setIsTermsChecked] = useState(false)
    const [isNewsletterSignupChecked, setIsNewsletterSignupChecked] = useState(true);

    const [alreadyHaveSubscription, setAlreadyHaveSubscription] = useState(false);

    const [updateSuccess, setUpdateSuccess] = useState(false);

    const [passwordVisible, setPasswordVisible] = useState(false);

    const [isGiftChecked, setIsGiftChecked] = useState(false);
    const [isGiftEmailInvalid, setIsGiftEmailInvalid] = useState(true);
    const [giftEmail, setGiftEmail] = useState('');
    const [giftMessage, setGiftMessage] = useState('');
    // xmas start
    const [recipientName, setRecipientName] = useState('');
    const [isRecipientNameInvalid, setIsRecipientNameInvalid] = useState(true);
    const [subscriptionDate, setSubscriptionDate] = useState(null);
    const [isSubscriptionDateInvalid, setIsSubscriptionDateInvalid] = useState(true);
    // xmas end

    const discount = useSelector(state => state.discountReducer);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }
    }, [stripe], []);

    const paymentMethodCard = checkedPaymentMethod === 1;
    const paymentMethodPaypal = checkedPaymentMethod === 2;

    const membership = location.state && location.state.subscription;
    const updateCard = location.state && location.state.update;
    const subscriptionId = location.state && location.state.id;
    let plan = null;

    if (membership) {
        plan = location.state.subscription.plan;
    } else if (props.membershipPromo) {
        plan = 1
    } else if (props.xmasPlan) {
        plan= props.xmasPlan
    }

    const handleSubmit = async (e) => {
        if (e) {
            e.preventDefault();
        }

        if (!stripe || !elements) {
            // disable submission if stripe is not loaded
            return;
        }

        setRegisterError(false);
        setIsLoading(true);

        const cardElement = elements.getElement(CardNumberElement);

        const paymentMethodReq = await stripe.createPaymentMethod({
            type: paymentMethodCard ? 'card' : 'paypal',
            card: paymentMethodCard ? cardElement : null,
            billing_details: paymentMethodCard ? {
                name: state.cardHolder,
            } : null
        })

        /*
         * handle membership
         */
        if (membership || props.membershipPromo || props.xmas) {
            // logged user
            let dataPlan = {
                plan: plan,
                gift: isGiftChecked,
                giftEmail: giftEmail,
                giftRecipientFirstName: recipientName,
                giftMessage: giftMessage,
                giftDeliveryDate: subscriptionDate,
                couponCode: discount.discountCode,
            }
            if (props.user) {
                if (!props.user.newsletter) {
                    dataPlan.newsletter = isNewsletterSignupChecked
                }

                if (paymentMethodCard) {
                    dataPlan.paymentMethod = paymentMethodReq.paymentMethod.id
                    
                    ApiService.createSubscription(dataPlan).then(
                        r => {
                            if (r.error) {
                                console.log(r)
                            } else {
                                const subscriptionId = r.data.id;
                                const paymentIntent = r.data.paymentIntent;
                                if (paymentMethodReq.paymentMethod) {
                                    if (paymentIntent.includes('seti')) {
                                        stripe.confirmCardSetup(paymentIntent, {
                                            payment_method: {
                                                type: 'card',
                                                card: cardElement,
                                                billing_details: {
                                                    name: state.cardHolder,
                                                }
                                            },
                                        }).then(
                                            r => {
                                                if (r.error) {
                                                    //handle error redirection
                                                    history.push('/payment-problem')
                                                } else {
                                                    //handle redirect after successful payment
                                                        history.push({
                                                            pathname: isGiftChecked ? 
                                                                props.xmasGift ? '/xmas-successful-gift' : '/subscription-successful-gift' 
                                                            : '/subscription-successful',
                                                            state: { 
                                                                subscriptionId: subscriptionId,
                                                                recipientName: recipientName,
                                                                plan: plan
                                                            }
                                                        })
                                                }
                                            }
                                        );                                    
                                    } else {
                                        stripe.confirmCardPayment(paymentIntent, {
                                            payment_method: paymentMethodReq.paymentMethod.id
                                        }).then(
                                            r => {
                                                if (r.error) {
                                                    //handle error redirection
                                                    history.push('/payment-problem');
                                                } else {
                                                    //handle redirect after successful payment
                                                        history.push({
                                                            pathname: isGiftChecked ? 
                                                                props.xmasGift ? '/xmas-successful-gift' : '/subscription-successful-gift' 
                                                            : '/subscription-successful',
                                                            state: { 
                                                                subscriptionId: subscriptionId,
                                                                recipientName: recipientName,
                                                                plan: plan
                                                            }
                                                        })
                                                }
                                            }
                                        );
                                    }
                                }
                            }
                        }
                    ).catch(e => {
                        if (e.response && e.response.data['hydra:description'] === 'User already has an active subscription') {
                            setAlreadyHaveSubscription(true)
                            setIsLoading(false)
                        }
                        if (e.response && e.response.data['hydra:description'].includes('Payment method not valid')) {
                            history.push('/payment-problem');
                        }
                    })
                }

                if (paymentMethodPaypal) {
                    ApiService.getPaypalSetupIntent().then(
                        r => {
                            const setupIntentClientSecret = r.data['setup_intent']['client_secret']
                            const paymentMethod = r.data['setup_intent']['payment_method']

                            dataPlan.paymentMethod = paymentMethod

                            ApiService.createSubscription(dataPlan).then(
                                r => {
                                    if (r.error) {
                                        console.log(r)
                                    } else {
                                        const subscriptionId = r.data.id;
                                        const paymentIntent = r.data.paymentIntent;
                                        if (paymentMethodReq.paymentMethod) {
                                            if (paymentIntent.includes('seti')) {
                                                stripe.confirmPayPalSetup(paymentIntent, {
                                                    return_url: 
                                                        config.apiGateway.URL.length ? 
                                                            isGiftChecked ? 
                                                                props.xmasGift ? `${config.apiGateway.URL}/xmas-successful-gift?recipient=${recipientName}&type=${plan}` : `${config.apiGateway.URL}/subscription-successful-gift` 
                                                            : `${config.apiGateway.URL}/subscription-successful?id=${subscriptionId}` 
                                                        : isGiftChecked ? 
                                                            props.xmasGift ? `http://localhost:3000/xmas-successful-gift?recipient=${recipientName}&type=${plan}` :'http://localhost:3000/subscription-successful-gift' 
                                                        : `http://localhost:3000/subscription-successful?id=${subscriptionId}`,
                                                }).then(
                                                    r => {
                                                        if (r.error) {
                                                            //handle error redirection
                                                            history.push('/payment-problem');
                                                        }
                                                    }
                                                );
                                            } else {
                                                stripe.confirmPayPalSetup(setupIntentClientSecret, {
                                                    return_url: 
                                                        config.apiGateway.URL.length ? 
                                                            isGiftChecked ? 
                                                                props.xmasGift ? `${config.apiGateway.URL}/xmas-successful-gift?recipient=${recipientName}&type=${plan}` : `${config.apiGateway.URL}/subscription-successful-gift` 
                                                            : `${config.apiGateway.URL}/subscription-successful?id=${subscriptionId}` 
                                                        : isGiftChecked ? 
                                                            props.xmasGift ? `http://localhost:3000/xmas-successful-gift?recipient=${recipientName}&type=${plan}` :'http://localhost:3000/subscription-successful-gift' 
                                                        : `http://localhost:3000/subscription-successful?id=${subscriptionId}`,
                                                    mandate_data: {
                                                        customer_acceptance: {
                                                            type: 'online',
                                                            online: {
                                                                infer_from_client: true
                                                            }
                                                        }
                                                    },
                                                }).then(
                                                    r => {
                                                        if (r.error) {
                                                            //handle error redirection
                                                            history.push('/payment-problem');
                                                        }
                                                    }
                                                );
                                            }
                                        }
                                    }
                                }
                            ).catch(e => {
                                if (e.response && e.response.data['hydra:description'] === 'User already has an active subscription') {
                                    setAlreadyHaveSubscription(true)
                                    setIsLoading(false)
                                }
                                if (e.response && e.response.data['hydra:description'].includes('Payment method not valid')) {
                                    history.push('/payment-problem');
                                }
                            })
                        }
                    )
                }
            } else {
                // registering user on pay now
                const dataRegister = {
                    email: state.eMail,
                    password: state.password,
                    firstName: state.firstName,
                    lastName: state.lastName,
                    countryCode: selected,
                    terms: true
                }
                ApiService.ecommerceRegister(dataRegister).then(r => {
                    localStorage.setItem('token', r.data.token);
                    localStorage.setItem('refresh_token', r.data.refresh_token);

                    dataPlan.newsletter = isNewsletterSignupChecked
                    
                    if (paymentMethodCard) {
                        dataPlan.paymentMethod = paymentMethodReq.paymentMethod.id
            
                        ApiService.createSubscription(dataPlan).then(
                            r => {
                                if (r.error) {
                                    console.log(r)
                                } else {
                                    const subscriptionId = r.data.id;
                                    const paymentIntent = r.data.paymentIntent;
                                    if (paymentMethodReq.paymentMethod) {
                                        if (paymentIntent.includes('seti')) {
                                            stripe.confirmCardSetup(paymentIntent, {
                                                payment_method: {
                                                    type: 'card',
                                                    card: cardElement,
                                                    billing_details: {
                                                        name: state.cardHolder,
                                                    }
                                                },
                                            }).then(
                                                r => {
                                                    if (r.error) {
                                                        //handle error redirection
                                                        history.push('/payment-problem')
                                                    } else {
                                                        //handle redirect after successful payment
                                                        history.push({
                                                            pathname: isGiftChecked ? 
                                                                props.xmasGift ? '/xmas-successful-gift' : '/subscription-successful-gift' 
                                                            : '/subscription-successful',
                                                            state: { 
                                                                subscriptionId: subscriptionId,
                                                                recipientName: recipientName,
                                                                plan: plan
                                                            }
                                                        })
                                                    }
                                                }
                                            );                                    
                                        } else {
                                            stripe.confirmCardPayment(paymentIntent, {
                                                payment_method: paymentMethodReq.paymentMethod.id
                                            }).then(
                                                r => {
                                                    if (r.error) {
                                                        //handle error redirection
                                                        history.push('/payment-problem');
                                                    } else {
                                                        //handle redirect after successful payment
                                                        history.push({
                                                            pathname: isGiftChecked ? 
                                                                props.xmasGift ? '/xmas-successful-gift' : '/subscription-successful-gift' 
                                                            : '/subscription-successful',
                                                            state: { 
                                                                subscriptionId: subscriptionId,
                                                                recipientName: recipientName,
                                                                plan: plan
                                                            }
                                                        })
                                                    }
                                                }
                                            );
                                        }
                                    }
                                }
                            }
                        ).catch(e => {
                            if (e.response && e.response.data['hydra:description'] === 'User already has an active subscription') {
                                setAlreadyHaveSubscription(true)
                                setIsLoading(false)
                            }
                            if (e.response && e.response.data['hydra:description'].includes('Payment method not valid')) {
                                history.push('/payment-problem');
                            }
                        })
                    }

                    if (paymentMethodPaypal) {
                        ApiService.getPaypalSetupIntent().then(
                            r => {
                                const setupIntentClientSecret = r.data['setup_intent']['client_secret']
                                const paymentMethod = r.data['setup_intent']['payment_method']
                  
                                dataPlan.paymentMethod = paymentMethod
                                
                                ApiService.createSubscription(dataPlan).then(
                                    r => {
                                        if (r.error) {
                                            console.log(r)
                                        } else {
                                            const subscriptionId = r.data.id;
                                            const paymentIntent = r.data.paymentIntent;
                                            if (paymentMethodReq.paymentMethod) {
                                                if (paymentIntent.includes('seti')) {
                                                    stripe.confirmPayPalSetup(paymentIntent, {
                                                        return_url: 
                                                            config.apiGateway.URL.length ? 
                                                                isGiftChecked ? 
                                                                    props.xmasGift ? `${config.apiGateway.URL}/xmas-successful-gift?recipient=${recipientName}&type=${plan}` : `${config.apiGateway.URL}/subscription-successful-gift` 
                                                                : `${config.apiGateway.URL}/subscription-successful?id=${subscriptionId}` 
                                                            : isGiftChecked ? 
                                                                props.xmasGift ? `http://localhost:3000/xmas-successful-gift?recipient=${recipientName}&type=${plan}` :'http://localhost:3000/subscription-successful-gift' 
                                                            : `http://localhost:3000/subscription-successful?id=${subscriptionId}`,
                                                    }).then(
                                                        r => {
                                                            if (r.error) {
                                                                //handle error redirection
                                                                history.push('/payment-problem');
                                                            }
                                                        }
                                                    );
                                                } else {
                                                    stripe.confirmPayPalSetup(setupIntentClientSecret, {
                                                        return_url: 
                                                            config.apiGateway.URL.length ? 
                                                                isGiftChecked ? 
                                                                    props.xmasGift ? `${config.apiGateway.URL}/xmas-successful-gift?recipient=${recipientName}&type=${plan}` : `${config.apiGateway.URL}/subscription-successful-gift` 
                                                                : `${config.apiGateway.URL}/subscription-successful?id=${subscriptionId}` 
                                                            : isGiftChecked ? 
                                                                props.xmasGift ? `http://localhost:3000/xmas-successful-gift?recipient=${recipientName}&type=${plan}` :'http://localhost:3000/subscription-successful-gift' 
                                                            : `http://localhost:3000/subscription-successful?id=${subscriptionId}`,
                                                        mandate_data: {
                                                            customer_acceptance: {
                                                                type: 'online',
                                                                online: {
                                                                    infer_from_client: true
                                                                }
                                                            }
                                                        },
                                                    }).then(
                                                        r => {
                                                            if (r.error) {
                                                                //handle error redirection
                                                                history.push('/payment-problem');
                                                            }
                                                        }
                                                    );
                                                }
                                            }
                                        }
                                    }
                                ).catch(e => {
                                    if (e.response && e.response.data['hydra:description'] === 'User already has an active subscription') {
                                        setAlreadyHaveSubscription(true)
                                        setIsLoading(false)
                                    }
                                    if (e.response && e.response.data['hydra:description'].includes('Payment method not valid')) {
                                        history.push('/payment-problem');
                                    }
                                })
                            }
                        )
                    }
                }).catch(
                    e => {
                        setRegisterError(true)
                        setIsLoading(false);
                    }
                )
            }
        } else if (updateCard) {
            ApiService.getSubscriptionIntents().then(
                r => {
                    if (r.error) {
                        console.log(r)
                    } else {
                        const clientSecret = r.data['hydra:member'][0].clientSecret;

                        if (paymentMethodReq.paymentMethod) {
                            stripe.confirmCardSetup(clientSecret, {
                                payment_method: {
                                    type: 'card',
                                    card: cardElement,
                                    billing_details: {
                                        name: state.cardHolder,
                                    }
                                },
                            }).then(
                                r => {
                                    if (r.error) {
                                        //handle error redirection
                                        history.push('/payment-problem')
                                    } else {
                                        const data = {
                                            paymentMethod: r.setupIntent.payment_method
                                        }
                                        //handle successful update
                                        ApiService.updateSubscriptionPaymentMethod(subscriptionId, data).then(r => {
                                            setUpdateSuccess(r.data.status)
                                            setIsLoading(false)

                                            // maybe Dispatch Action in future
                                            document.body.classList.remove('show-banner-payment-failed')

                                            history.push('/subscription-details')
                                        })
                                    }
                                }
                            );
                        }
                    }
                }
            )

        } else {
            /*
             * handle order
             */
            if (props.user) {
                //get cartId of current user
                let cartId = props.user.cart.uuid;
                //check if all items are available for user
                ApiService.checkGeoblock(cartId).then(
                    r => {
                        if (r.data.updated) {
                            //go back to shopping bag because
                            //there are items in cart
                            //that are geoblocked for current user
                            history.push({
                                pathname: '/shopping-bag'
                            })
                        } else {
                            //can continue, all cart items
                            //are available for the user

                            //create payment intent
                            let dataDiscount;
                            if (discount.discountCode) {
                                dataDiscount = {
                                    couponCode: discount.discountCode
                                }
                            } else {
                                dataDiscount = {}
                            }
                            ApiService.createPaymentIntent(cartId, dataDiscount).then(
                                r => {
                                    if (paymentMethodReq.paymentMethod) {
                                        if (paymentMethodCard) {
                                            stripe.confirmCardPayment(r.data.clientSecret, {
                                                payment_method: paymentMethodReq.paymentMethod.id
                                            }).then(
                                                r => {
                                                    if (r.error) {
                                                        //handle error redirection
                                                        history.push('/payment-problem');
                                                    } else {
                                                        //handle redirect after successful payment
                                                        history.push({
                                                            pathname: '/payment-successful',
                                                            state: { newUser: user ? false : true }
                                                        })
                                                    }
                                                }
                                            );
                                        }

                                        if (paymentMethodPaypal) {
                                            stripe.confirmPayPalPayment(r.data.clientSecret, {
                                                payment_method: paymentMethodReq.paymentMethod.id,
                                                return_url: 
                                                    config.apiGateway.URL.length ? `${config.apiGateway.URL}/payment-successful?newuser=${user ? false : true}` : `http://localhost:3000/payment-successful?newuser=${user ? false : true}`,
                                            }).then(
                                                r => {
                                                    if (r.error) {
                                                        //handle error redirection
                                                        history.push('/payment-problem');
                                                    }
                                                }
                                            );
                                        }
                                    }
                                }
                            )
                        }
                    }
                )
            } else {
                // registering user on pay now
                const data = {
                    email: state.eMail,
                    password: state.password,
                    firstName: state.firstName,
                    lastName: state.lastName,
                    countryCode: selected,
                    // terms: isTermsChecked
                }
                ApiService.registerUserOnPayment(props.cartUuid, data).then(
                    r => {
                        localStorage.setItem('token', r.data.token);
                        localStorage.setItem('refresh_token', r.data.refresh_token);
                        //getting cart from cookies
                        let cartId = Cookies.get('cartId');

                        //check if items are not geo blocked
                        //for newly registered user
                        ApiService.checkGeoblock(cartId).then(
                            r => {
                                if (r.data.updated) {
                                    //if some of the items are geo blocked
                                    //go back to /shopping-bag and mark them
                                    history.push({
                                        pathname: '/shopping-bag'
                                    })
                                } else {
                                    //create payment intent if all items
                                    //are available for current registered user
                                    let dataDiscount;
                                    if (discount.discountCode) {
                                        dataDiscount = {
                                            couponCode: discount.discountCode
                                        }
                                    } else {
                                        dataDiscount = {}
                                    }
                                    ApiService.createPaymentIntent(cartId, dataDiscount).then(
                                        r => {
                                            if (paymentMethodReq.paymentMethod) {
                                                if (paymentMethodCard) {
                                                    stripe.confirmCardPayment(r.data.clientSecret, {
                                                        payment_method: paymentMethodReq.paymentMethod.id
                                                    }).then(
                                                        r => {
                                                            if (r.error) {
                                                                //handle error redirection
                                                                cookie.remove('cartId');
                                                                history.push('/payment-problem');
                                                            } else {
                                                                //handle redirect after successful payment
                                                                history.push({
                                                                    pathname: '/payment-successful',
                                                                    state: { newUser: user ? false : true }
                                                                })
                                                            }
                                                        }
                                                    );
                                                }

                                                if (paymentMethodPaypal) {
                                                    stripe.confirmPayPalPayment(r.data.clientSecret, {
                                                        payment_method: paymentMethodReq.paymentMethod.id,
                                                        return_url: 
                                                            config.apiGateway.URL.length ? `${config.apiGateway.URL}/payment-successful?newuser=${user ? false : true}` : `http://localhost:3000/payment-successful?newuser=${user ? false : true}`,
                                                    }).then(
                                                        r => {
                                                            if (r.error) {
                                                                //handle error redirection
                                                                history.push('/payment-problem');
                                                            }
                                                        }
                                                    );
                                                }
                                            }
                                        }
                                    )
                                }
                            }
                        )
                    }
                ).catch(
                    e => {
                        console.log(e);
                        setRegisterError(true)
                        setIsLoading(false);
                    }
                )
            }

            if (window.dataLayer) {
                const cartItems = [];
                if (props.cart) {
                    props.cart.map((book, index) => {
                        const locations = []
                        if (book.audioBook.locations && book.audioBook.locations.length) {
                            book.audioBook.locations.map((location) => {
                                return locations.push(location.title)
                            })
                        }
                        const tags = []
                        if (book.audioBook.tags && book.audioBook.tags.length) {
                            book.audioBook.tags.map((tag) => {
                                return tags.push(tag.title)
                            })
                        }
                        const authors = []
                        if (book.audioBook.authors && book.audioBook.authors.length) {
                            book.audioBook.authors.map((author) => {
                                return authors.push(author.fullName)
                            })
                        }
                        const narrators = []
                        if (book.audioBook.narrators && book.audioBook.narrators.length) {
                            book.audioBook.narrators.map((narrator) => {
                                return narrators.push(`${narrator.firstName} ${narrator.lastName}`)
                            })
                        }
                        return cartItems.push({
                            item_id: book.audioBook.slug,
                            item_name: book.audioBook.title,
                            item_brand: book.audioBook.publisher ? book.audioBook.publisher?.name : '',
                            item_category: book.audioBook.category ? book.audioBook.category?.name : '',
                            item_locations: locations?.length ? locations.join(', ') : '',
                            item_tags: tags?.length ? tags.join(', ') : '',
                            item_price: book.audioBook.price,
                            item_author: authors?.length ? authors.join(', ') : '',
                            item_narrator: narrators?.length ? narrators.join(', ') : '',
                            item_translator: book.audioBook.translator ? `${book.audioBook.translator.firstName} ${book.audioBook.translator.lastName}` : '',
                            item_length: `${book.audioBook.lengthHours}:${book.audioBook.lengthMin}`,
                        })
                    })
                }

                window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                window.dataLayer.push({
                    event: "add_payment_info",
                    ecommerce: {
                        currency: "GBP",
                        value: props.cart[0].cart.sumCheckedForPayment,
                        coupon: discount.discountCode,
                        discount: discount.relativeDiscount,
                        payment_type: paymentMethodCard ? 'Credit Card' : 'Paypal',
                        items: cartItems
                    }
                });
            }
        }
    };

    const handleChange = e => {
        const value = e.target.value;
        setState({
            ...state,
            [e.target.name]: value
        });

        if (e.target.name === 'password' && e.target.value.length > 7) {
            document.querySelector('#password-length').style.visibility = 'hidden';
        }
    }

    const handleBlur = e => {
        const value = e.target.value
        if (e.target.name === 'eMail') {
            const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            setValidationState({
                ...validationState,
                [e.target.name]: regex.test(String(e.target.value).toLowerCase())
            })
        } else if (e.target.name === 'password') {
            setValidationState({
                ...validationState,
                [e.target.name]: value.length > 7 ? true : false
            })
        } else {
            setValidationState({
                ...validationState,
                [e.target.name]: value.length > 1 ? true : false
            })
        }

        if (e.target.name === 'password' && e.target.value.length < 8) {
            document.querySelector('#password-length').style.visibility = 'visible';
        }
    }

    const handleChangeCardElements = (e, type) => {
        setCardElementsComplete({ ...cardElementsComplete, [type]: e.complete });
    }

    const handlePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    }

    const handleCheckedPaymentMethod = (data) => {
        setCheckedPaymentMethod(data)
    }

    // const handleCheckedTerms = () => {
    //     setIsTermsChecked(prev => !prev)
    // }

    const handleCheckedNewsletterSignup = () => {
        setIsNewsletterSignupChecked(prev => !prev)
    }

    const pullData = (data) => {
        setIsGiftChecked(data.checked)
        setIsGiftEmailInvalid(data.invalidEmail)
        setGiftEmail(data.email)
        setGiftMessage(data.message)
        setRecipientName(data.name)
        setIsRecipientNameInvalid(data.invalidName)
    }

    const pullDate = (data) => {
        setSubscriptionDate(data.date)
        setIsSubscriptionDateInvalid(data.invalidDate)
    }

    useEffect(() => {
        if (paymentMethodPaypal) {
            handleSubmit()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentMethodPaypal])

    const cardStyle = {
        base: {
            fontFamily: 'Gill Sans Nova, serif',
            fontSmoothing: "antialiased",
            fontSize: '16px',
            letterSpacing: '0.04em',
            color: '#000',
            fontWeight: 400,
            "::placeholder": {
                color: "#BDBAB1"
            }
        },
        invalid: {
            color: "rgba(255, 0, 0, 0.8)",
            backgroundColor: "rgba(255, 0, 0, 0.08)"
        }
    }

    const options = {
        style: cardStyle
    }

    const cardNumberOptions = {
        placeholder: 'Number',
        style: cardStyle
    }

    const show = membership || props.xmas;

    return (
        <StyledCheckoutForm id="payment-form" onSubmit={handleSubmit}>
            {props.xmasGift && <h2 className='mt-60 mb-45'>Recipient's details</h2> }

            {(membership || props.xmasGift) && <SendMembershipAsGift pullData={pullData} xmasGift={props.xmasGift} />}

            {props.xmasGift && <Date pullDate={pullDate} label='Select date to send gift*' />}

            {!props.user ?
                <>
                    <h2 className='mt-60'>Your details</h2>
                     {!props.user && !props.membershipPromo && 
                        <Button id="open-auth" type='button'>
                            Already have an account? <span>Log in</span>
                        </Button>}

                    <Input>
                        <label>Your email{show ? '*' : ''}</label>
                        <input className={`bg-white mb-30 ${!validationState.eMail ? 'invalid' : ''}`}
                            type="eMail"
                            name="eMail"
                            id="eMail"
                            value={state.eMail}
                            onChange={handleChange}
                            onBlur={handleBlur} />
                    </Input>
                    <Input>
                        <label>Your first name{show ? '*' : ''}</label>
                        <input className={`bg-white ${show ? '' : 'mb-30'} ${!validationState.firstName ? 'invalid' : ''}`}
                            type="text"
                            name="firstName"
                            id="firstName"
                            value={state.firstName}
                            onChange={handleChange}
                            onBlur={handleBlur} />
                            {show ? <div className='mb-30 input-description'>Your first name will be shown in the email</div> : null}
                    </Input>
                    <Input>
                        <label>Your last name{show ? '*' : ''}</label>
                        <input className={`bg-white mb-30 ${!validationState.lastName ? 'invalid' : ''}`}
                            type="text"
                            name="lastName"
                            id="lastName"
                            value={state.lastName}
                            onChange={handleChange}
                            onBlur={handleBlur} />
                    </Input>
                    <Input className="country-flags mb-30">
                        <label>Country{show ? '*' : ''}</label>
                        <ReactFlagsSelect
                            selected={selected}
                            onSelect={code => setSelected(code)}
                            placeholder="Your country"
                            searchable
                            searchPlaceholder="Search country"
                            selectedSize={16}
                        />
                    </Input>
                    <Input className="relative">
                        <label>Password{show ? '*' : ''}</label>
                        <input className={`bg-white ${show ? '' : 'mb-30'} ${!validationState.password ? 'invalid' : ''}`}
                            type={passwordVisible ? "text" : "password"}
                            name="password"
                            id="password"
                            value={state.password}
                            onChange={handleChange}
                            onBlur={handleBlur} />
                        <i onClick={handlePasswordVisibility} className={`icon-eye password-field--eye ${passwordVisible && "show"}`}></i>
                        <span id="password-length" className="font-12 font-italic font-secondary">Password must be at least 8 characters long</span>
                        {show ? <div className='mb-30 mt-18 input-description'>Password should be at least 8 characters long. A combination of letters, numbers and special characters are best.</div> : null}
                    </Input>
                </>
                : null}

            {!updateCard ? 
                <PaymentMethod>
                    {/* <div>
                        <input type="radio" id="payWithCard" name="paymentMethod" value="payWithCard" checked={paymentMethodCard} onChange={() => handleCheckedPaymentMethod(1)}/>
                        <label htmlFor='payWithCard'>
                            <i className="icon-checked" />
                            <i className="icon-not-checked" />
                            Pay with Card                                   
                        </label>  
                    </div>
                    <div>
                        <input type="radio" id="payWithPaypal" name="paymentMethod" value="payWithPaypal" checked={paymentMethodPaypal} onChange={() => handleCheckedPaymentMethod(2)}/>
                        <label htmlFor='payWithPaypal'>
                            <i className="icon-checked" />
                            <i className="icon-not-checked" />
                            Pay with PayPal                                   
                        </label>  
                    </div> */}
                    <h2>Choose payment method</h2>
                    <p>By completing the payment below, you agree to our <Link id='gtm-checkout-terms-conditions' to='/info/terms-conditions'>Terms and Conditions</Link>.</p>

                    <div style={{
                            pointerEvents:
                            isLoading ||
                            !stripe ||
                            !elements ||
                            ((membership || props.xmasGift) && isGiftChecked && isGiftEmailInvalid) ||
                            (props.xmasGift && isRecipientNameInvalid) ||
                            (props.xmasGift && isSubscriptionDateInvalid) ||
                            // (props.xmas && !isTermsChecked) ||
                            (!props.user && state.firstName === "") ||
                            (!props.user && state.lastName === "") ||
                            (!props.user && state.eMail === "") ||
                            (!props.user && !validationState.firstName) ||
                            (!props.user && !validationState.lastName) ||
                            (!props.user && !validationState.eMail) ||
                            (!props.user && !validationState.password) ||
                            (!props.user && !selected.length) ||
                            props.isLoadingDiscount ? 'none' : 'initial'
                        }}
                        onClick={() => handleCheckedPaymentMethod(2)}
                    >
                        <img src={payPal} alt="Pay with PayPal" />
                    </div>

                    <Divider>or pay with card</Divider>
                </PaymentMethod>
                :
                null
            }

            {/* {paymentMethodCard ? */}
                <CardDetails className={`${updateCard ? 'pt-90' :''} pb-30`}>
                    <div>
                        <label>Cardholder name{show ? '*' : ''}</label>
                        <input className={`bg-white mb-30 ${!validationState.cardHolder ? 'invalid' : ''}`}
                            type="text"
                            placeholder="Full name"
                            name="cardHolder"
                            id="cardHolder"
                            value={state.cardHolder}
                            onChange={handleChange}
                            onBlur={handleBlur} />
                    </div>

                    <label>Card Information{show ? '*' : ''}</label>

                    <CardElements >
                        <CardInput>
                            <CardNumberElement onChange={e => handleChangeCardElements(e, "number")} options={cardNumberOptions} id="payment-element" />
                            <img src={cardImage} alt="Visa, MasterCard, American Express, Discover" />
                        </CardInput>
                        <CardInput><CardExpiryElement onChange={e => handleChangeCardElements(e, "expiry")} options={options} /></CardInput>
                        <CardInput><CardCvcElement onChange={e => handleChangeCardElements(e, "cvc")} options={options} /></CardInput>
                    </CardElements>
                </CardDetails>
                {/* : null
            } */}

            {!updateCard && (membership || props.xmas) && (!props.user || (props.user && !props.user.newsletter)) && 
                <Newsletter>
                    <Terms>
                        <div>
                            <input className='c-field-checkbox__input' type="checkbox" name="newsletterSignup" id="newsletterSignup" defaultChecked={isNewsletterSignupChecked} onChange={handleCheckedNewsletterSignup} />
                            <label id='gtm-free-subscription-newsletter' className="c-field-checkbox__label" htmlFor="newsletterSignup"></label>
                            <span>Yes, I would like newsletters and other great writing</span>
                        </div> 
                    </Terms>
                </Newsletter>
            }
            
        <Beige>
            <button
                id={`${updateCard ? 'gtm-card-update' : props.membershipPromo ? 'gtm-subscribe' : 'gtm-pay'}`}
                type="submit"
                disabled={
                    isLoading ||
                    !stripe ||
                    !elements ||
                    ((membership || props.xmasGift) && isGiftChecked && isGiftEmailInvalid) ||
                    (props.xmasGift && isRecipientNameInvalid) ||
                    (props.xmasGift && isSubscriptionDateInvalid) ||
                    // (props.xmas && !isTermsChecked) ||
                    (!props.user && state.firstName === "") ||
                    (!props.user && state.lastName === "") ||
                    (!props.user && state.eMail === "") ||
                    (!props.user && !validationState.firstName) ||
                    (!props.user && !validationState.lastName) ||
                    (!props.user && !validationState.eMail) ||
                    (!props.user && !validationState.password) ||
                    (!props.user && !selected.length) ||
                    (paymentMethodCard && state.cardHolder === "") ||
                    (paymentMethodCard && !cardElementsComplete.number )||
                    (paymentMethodCard && !cardElementsComplete.expiry) ||
                    (paymentMethodCard && !cardElementsComplete.cvc) ||
                    props.isLoadingDiscount
                }
                className={`sa-button-red sa-button-rounded mb-3 ${isLoading ? 'submitting' : ''}`}>
                {!updateSuccess ? updateCard ? 'Update' : props.membershipPromo ? 'Subscribe' : 'Pay now' : updateSuccess !== 'success' && 'Ups! Error!'}
                <SpiracleLogoSmall style={{ filter: 'invert()' }} />
            </button>
            {registerError && 
                <div className="c-error-message mb-15">
                    <div>This email already exists with a user account, please sign in</div>
                </div>
            }
            {alreadyHaveSubscription && 
                <div className="c-error-message mb-15">
                    <div>You already have an active subscription</div>
                </div>
            }
        </Beige>
            {/* <OrderFooter className="text-center mt-12">
                <Terms>
                    {props.xmas ? 
                        <div>
                            <input className='c-field-checkbox__input' type="checkbox" name="termsConditions" id="termsConditions" checked={isTermsChecked} onChange={handleCheckedTerms} />
                            <label className="c-field-checkbox__label" htmlFor="termsConditions"></label>
                            <span>I agree to the <Link id='gtm-checkout-terms-conditions' to='/info/terms-conditions'>T&amp;Cs</Link></span>
                        </div> 
                    : <Link id='gtm-checkout-terms-conditions' to='/info/terms-conditions'>Terms &amp; Conditions</Link>}
                    <p>powered by <strong>stripe</strong></p>
                </Terms>
            </OrderFooter> */}
        </StyledCheckoutForm>
    );
}