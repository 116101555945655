import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import ApiService from 'services/api.service';
import Loader from 'shared/Loader/Loader';
import PageMeta from '../../shared/PageMeta/PageMeta';
import { Heading, StyledOrderConfirmation, Terms } from '../Order/style';
import PaypalProblem from 'components/UI/PaypalProblem/PaypalProblem';
import { ReactComponent as SpiracleLogoSmall } from '../../images/Spiral.svg';

import * as userActionTypes from '../../store/actions/userActions';
import * as discountActionTypes from '../../store/actions/discountActions';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { subscriptionPlans } from 'consts/consts';

const { freeTrial } = subscriptionPlans;

class MembershipConfirmationGiftXmas extends Component {

    state = {
        isLoading: false,
        isLoadingSubscription: false,
        recipient: this.props.location.state ? this.props.location.state.recipientName : new URLSearchParams(this.props.location.search).get('recipient'),
        type: this.props.location.state ? this.props.location.state.plan : parseInt(new URLSearchParams(this.props.location.search).get('type')),
        subscriptionId: '',
        isTermsChecked: false,
        isPaypalFailed: new URLSearchParams(this.props.location.search).get('redirect_status') === 'failed',
    }

    componentDidMount() {
        if (localStorage.getItem("token") == null) {
            this.props.history.push("/access-denied");
        } else {
            if (!this.props.user && localStorage.getItem('token')) {
                this.setState({
                    isLoading: true
                })

                ApiService.getUser().then(
                    user => {
                        this.props.onSetUser(user.data);

                        this.setState({
                            isLoading: false
                        })
                    }
                )
            } else {
                if (!this.state.isPaypalFailed) {
                this.props.onSetPaysSomeoneSubscription();
                }
            }

            this.props.onRemoveDiscount();
        }
    }

    handleCheckedTerms = () => {
        this.setState({
            isTermsChecked: !this.state.isTermsChecked
        })
    }

    handleSubscribe = () => {
        const data = {
            plan: freeTrial.plan
        }

        this.setState({
            isLoadingSubscription: true
        })

        ApiService.createSubscriptionWithoutPayment(data).then(r => {
            this.setState({
                isLoadingSubscription: false,
                subscriptionId: r.data.id
            }, () => {
                this.props.history.push({
                    pathname: '/subscription-successful-trial',
                    state: { subscriptionId: this.state.subscriptionId }
                })
            })
        }).catch(e => {
            console.log('e', e)
            if (e.response && e.response.data['hydra:description'] === 'User already has trial subscription') {
                this.props.history.push('/xmas?plan=2');
            }
        })
    }

    render() {
        const subscription = Object.values(subscriptionPlans).find((item) => item.plan === this.state.type);
        const name = subscription?.name;
        const type = subscription?.type;

        let content = <Loader />

        if (!this.state.isLoading) {
            if (!this.state.isPaypalFailed) {
            content = <StyledOrderConfirmation className="container container__big">
                            <PageMeta>
                                <title>Successful | Spiracle</title>
                                <link rel="canonical" href={window.location.href} />
                            </PageMeta>
            
                            <Heading className="font-20 lh-29 font-italic">
                                Thank you for your purchase!
                            </Heading>

                            <p className='mb-53 font-18 text-center'>
                                We are sure {this.state.recipient} will enjoy their {`${name} ${type}`} subscription.
                            </p>

                            {this.props.user && !this.props.user.currentSubscription ?
                                <div>
                                    <p className='text-center'>
                                        We would love to offer you a <span className='font-700'>free 1 month Premium Membership so you can enjoy Spiracle</span> too. <br /> <br /> 
                                        No Credit Card required. <br /> <br /> 
                                        At the end of your 1st month if you have not added your CC details we will revert you subscription to the Freemium subscription.
                                    </p>

                                    <button
                                        id='gtm-subscribe-to-your-free-month'
                                        onClick={this.handleSubscribe}
                                        disabled={
                                            this.state.isLoadingSubscription ||
                                            !this.state.isTermsChecked
                                        }
                                        className={`c-btn c-btn__ghost c-btn__ghost--square full-width font-secondary font-14 letter-spacing-15 font-uppercase text-center mt-30 letter-spacing-15 ${this.state.isLoadingSubscription ? 'submitting' : ''}`}
                                    >
                                        Subscribe to your free month
                                        <SpiracleLogoSmall />
                                    </button>
                                    <Terms className='mt-12'>
                                        <div>
                                            <input className='c-field-checkbox__input' type="checkbox" name="termsConditions" id="termsConditions" checked={this.state.isTermsChecked} onChange={this.handleCheckedTerms} />
                                            <label className="c-field-checkbox__label" htmlFor="termsConditions"></label>
                                            <span>I agree to the <Link id='gtm-membership-confirmation-xmas-gift-contact-support' to='/info/terms-conditions'>T&amp;Cs</Link></span>
                                        </div> 
                                    </Terms>
                                </div>
                                :
                                null
                            }
                            
                       </StyledOrderConfirmation>
            } else {
                content = content = <PaypalProblem />
            }
        }

        return content
          
    }
}

const mapStateToProps = state => ({
    user: state.userReducer.user,
})

const mapDispatchToProps = dispatch => {
    return {
        onSetUser: (user) => dispatch(userActionTypes.onSetUser(user)),
        onSetPaysSomeoneSubscription: () => dispatch(userActionTypes.onSetPaysSomeoneSubscription()),
        onRemoveDiscount: () => dispatch(discountActionTypes.onRemoveDiscount()),
    }
}

const membershipConfirmationGiftXmas = withRouter(MembershipConfirmationGiftXmas)

export default connect(mapStateToProps, mapDispatchToProps)(membershipConfirmationGiftXmas)
